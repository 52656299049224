import React, { useState } from "react";
import { Modal, Input, Button, Form } from "antd";

const QuoteModal = ({ isOpen, onClose }) => {
  const [form] = Form.useForm();
  const [responseMessage, setResponseMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = (values) => {
    setIsLoading(true);
    fetch("https://colitely.com/website-backend/api-v1/send_message.php", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(values), // ✅ Now sending AntD form values
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data.message);
        setIsLoading(false);
        setResponseMessage(data.message);
        form.resetFields(); // ✅ Reset form after success
      })
      .catch((error) => {
        setIsLoading(false);
        setResponseMessage("Error sending message");
        console.error("Error:", error);
      });
  };

  return (
    <Modal title="Request a Quote" open={isOpen} onCancel={onClose} footer={null}>
      {responseMessage && <p>{responseMessage}</p>}
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <Form.Item name="fname" label="Your Name" rules={[{ required: true, message: "Please enter your name" }]}>
          <Input placeholder="Your Name" />
        </Form.Item>

        <Form.Item name="email" label="Your Email" rules={[{ required: true, type: "email", message: "Enter a valid email" }]}>
          <Input placeholder="Your Email" />
        </Form.Item>

        <Form.Item name="subject" label="Company Name">
          <Input placeholder="Company Name" />
        </Form.Item>

        <Form.Item name="message" label="Project Details" rules={[{ required: true, message: "Please provide details" }]}>
          <Input.TextArea placeholder="Project Details" rows={4} />
        </Form.Item>

        <Button 
          style={{ background: "#7620ff", borderColor: "#7620ff" }}
          type="primary" htmlType="submit" loading={isLoading} block
        >
          Submit Request
        </Button>
      </Form>
    </Modal>
  );
};

export default QuoteModal;
